/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Container } from "react-bootstrap"

import LogoHCWhite from '../../images/svg/hc_logo_whitev3.inline.svg'
const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<div className="d-flex flex-column bg-white min-vh-100 services-global">
				<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
				<div className="mb-5">
					<main className="bg-white">{children}</main>
				</div>
				<footer className="bg-black mt-auto py-3 mt-2">
					<Container>
						<div>
							<LogoHCWhite style={{'width':'150px'}}/>  
						</div>
						<div className="text-white mt-2">
							<div className="d-flex">
								<div>
								© {new Date().getFullYear()} {data.site.siteMetadata?.title}
								</div>
							</div>
						</div>
					</Container>
				</footer>
			</div>
		</>
	)
}

export default Layout
