import React, { useState, useEffect , useCallback } from "react"
import Layout from "../components/services/layout"
import Seo from "../components/seo"
import ProductSelector from '../components/services/product_selector';
import CustomerCheck from '../components/services/customer_check';
import PhoneInput , {isValidPhoneNumber} from 'react-phone-number-input'

import axios from "axios";
import _ from "lodash"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as styles from "../components/style/gift-program.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const {GATSBY_BACKEND_URL} = process.env;

const GiftProgram = () => {
  	const [categories, setCategories] = useState([]);
  	const [products, setProducts] = useState([]);
  	const [showFormGift, setShowFormGift] = useState(false);
  	const [sendGiftName, setSendGiftName] = useState('');
  	const [sendGiftEmail, setSendGifEmail] = useState('');
  	const [sendGiftPhone, setSendGiftPhone] = useState('');
  	const [sendingGift, setSendingGift] = useState(false);
	const [sendingGiftProcess, setSendingGiftProcess] = useState([]);
  	const [customerData, setCustomerData] = useState([]);
  	const [generatingShareLink, setGeneratingShareLink] = useState(false);
  	const [shareLinkProcess, setShareLinkProcess] = useState([]);
  	const [shareLinkGuid, setShareLinkGuid] = useState('');

  	const getCategoriesData = useCallback(async () => {
		await axios.get(`${GATSBY_BACKEND_URL}products/categories` , {})
				.then((res) => {
					setCategories(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (typeof error.response === 'object' && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setCategories(result);
				});
	},[])

	const updateProductsSelected = (items) => {
		setProducts(items);
  	}

	const updateCustomerData = (data) => {
		setCustomerData(data);
  	}

	const updateShowNext = (value) => {
		if(!products.length) {
			setProducts([{'id':'no_selection','name':"Healthycell"}]);
		}
		setShowFormGift(value);

		var count_waiting = 0;
		var waitingForm = setInterval(function() {
			var elem = document.getElementById("scroll_form_gift");
			if(typeof(elem) != 'undefined' && elem != null) {
				elem.scrollIntoView();
				clearInterval(waitingForm);
			}
			count_waiting = count_waiting + 1;
			if(count_waiting === 200) {
				clearInterval(waitingForm);
			}
		}, 200);
  	}

	const sendGiftPost = (event) => {
		setSendingGift(true);
		event.preventDefault();

		setSendingGiftProcess([]);
		var request = {
						'access_code':shareLinkProcess.id,
						'name':sendGiftName,
						'email':sendGiftEmail,
						'phone':sendGiftPhone
						};
		axios({
			method: "post",
			url: `${GATSBY_BACKEND_URL}customer/send-share-link`,
			data: request
		  })
			.then(response => {
				var data = {success:true};
				setSendingGiftProcess(data);
				setSendGiftName('');
				setSendGifEmail('');
				setSendGiftPhone('');
			})
			.catch(error => {
				var result = {};
				if (typeof error.response.data === 'object') {
					if(error.response.data.errors) {
						result.errors = _.flatten(_.toArray(error.response.data.errors));
					} else if (error.response.data.message) {
						result.errors = [error.response.data.message];
					}
				} else {
					result.errors = ['Something went wrong. Please try again.'];
				}

				setSendingGiftProcess(result);
			})
			.then(() => {
				setSendingGift(false);
			})
	}

	const getShareLink = useCallback((items) => {		
		var list_products = [];
		items.forEach((item) => {
			list_products.push(item.id);
		})

		/*if(!list_products.length) {
			return true;
		}*/
		if(customerData.id === undefined) {
			return true;
		}

		setGeneratingShareLink(true);
		setShareLinkProcess([]);
		setShareLinkGuid('');

		axios({
			method: "post",
			url: `${GATSBY_BACKEND_URL}customer/share-link`,
			data: {'customer': customerData,
					'products': list_products
				  }
			})
			.then(response => {
				var data = response.data;
				if(data.id !== undefined) {
					setShareLinkGuid(`https://my.healthycell.com/gift-program/${data.id}`);
				}
				setShareLinkProcess(data);
			})
			.catch(error => {
				var result = {};
				if (typeof error.response.data === 'object') {
					if(error.response.data.errors) {
						result.errors = _.flatten(_.toArray(error.response.data.errors));
					} else if (error.response.data.message) {
						result.errors = [error.response.data.message];
					}
				} else {
					result.errors = ['Something went wrong. Please try again.'];
				}

				setShareLinkProcess(result);
			})
			.then(() => {
				setGeneratingShareLink(false);
			});
	},[customerData]);

	useEffect(() => {
		if(showFormGift) {
			getShareLink(products);
		}
	}, [products , showFormGift , getShareLink]);

	useEffect( () => {
		getCategoriesData()
	}, [getCategoriesData]) // execute in the first render

  return (
	<Layout>
		<div className={`mb-5 bg-dark`}>
			<div className="container text-center text-white py-5">
				<h1>Welcome to our Gift Program</h1>
				<h4>Share the Healthycell difference with others</h4>
			</div>
		</div>
		<div className="container mx-auto">
			<CustomerCheck updateCustomerData={updateCustomerData}/>			
			{customerData && customerData.id !== undefined &&
				<>
				<div className="separator d-flex justify-content-center align-items-center my-5">
					<div className="line"></div>
					<div className="arrow d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={'angle-down'} /></div>
				</div>
				<div className="text-center">
					<h1 className="mb-3">Would you like to recommend a specific product?</h1>
					<h3 className="mb-3 font-gotham-rounded-medium">Select the product below you want to recommend</h3>
					<p><b>(limit one gift per household)</b></p>
				</div>
				<div>
				<ProductSelector onlyGiftItems={true} items={categories} multiselect={false} updateProductsSelected={updateProductsSelected} updateContinueAction={updateShowNext} allowNoSelection={true} />
				</div>
				{ showFormGift &&
					<>
						<div className="separator d-flex justify-content-center align-items-center my-3">
							<div className="line"></div>
							<div className="arrow d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={'angle-down'} /></div>
						</div>
						<div className="px-0 px-lg-5 pt-5">
							{generatingShareLink === true &&
								<>
								<Row>
									<Col>
										<div className="text-center py-2">
											<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										</div>
									</Col>
								</Row>
								</>
							}
							{products.length > 0 && !generatingShareLink &&
								<>
								{shareLinkProcess.id && 
									<>
									<div id="scroll_form_gift">
										{products.map((_product,p_i) => (
											<div key={`product_selected_${p_i}`}>
												<div className={`text-center mb-5 mx-auto ${styles.formWithTitle}`}>
													<h3 className="mb-3 font-gotham-rounded-medium">Leave the contact information of the person you would like to give <u>{_product.name}</u> to down below.</h3>
												</div>
												<Form onSubmit={sendGiftPost} autoComplete="off">
													<Row className='justify-content-center'>
														<Col xs="12" lg="3" className=''>
															<Form.Group className="shadow-sm">
																<Form.Control
																	onChange={event => setSendGiftName(event.target.value)}
																	placeholder="name"
																	aria-label="Name"
																	type="text"
																	value={sendGiftName}
																	required
																	style={{'lineHeight':2}}
																/>
															</Form.Group>
														</Col>
														<Col xs="12" lg="3" className=''>
															<Row className='gx-0 align-items-center'>
																<Col xs="12" lg="12" className=''>
																	<Form.Group className="shadow-sm">
																		<Form.Control
																			onChange={event => setSendGifEmail(event.target.value)}
																			placeholder="email"
																			aria-label="Email"
																			type="text"
																			value={sendGiftEmail}
																			style={{'lineHeight':2}}
																			autoComplete="off"
																		/>
																	</Form.Group>
																</Col>
																{/* <Col xs="12" lg="2" className=''>
																	<div className={`${styles.orCircleWrapper}`}>
																		<div className={`${styles.orCircle} ${styles.small}`}>OR</div>
																	</div>
																</Col>
																<Col xs="12" lg="5" className=''>
																	<PhoneInput
																		international
																		countryCallingCodeEditable={false}
																		defaultCountry="US"
																		placeholder="phone"
																		value={sendGiftPhone}
																		onChange={setSendGiftPhone}
																		error={sendGiftPhone ? (isValidPhoneNumber(sendGiftPhone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
																</Col>*/}
															</Row>
														</Col>
														<Col xs="12" lg="3" className='my-3 my-lg-0 text-center text-lg-start'>
															<Button disabled={sendingGift || (sendGiftPhone && !isValidPhoneNumber(sendGiftPhone)) } className='h-100 px-5 font-gotham-rounded-medium' variant="dark" type='submit'>Send Gift</Button>
														</Col>
													</Row>
													{/* sendGiftPhone && !isValidPhoneNumber(sendGiftPhone) &&
														<Row className='gx-0 align-items-center'>
															<Col xs="12" className='text-center'>
																<p className="my-2" style={{color: 'red'}}>Invalid phone number</p>
															</Col>
														</Row>
														*/}
												</Form>
												<div>
													{sendingGift === true &&
														<>
														<Row>
															<Col>
																<div className="text-center py-2">
																	<div className="spinner-border" style={{width: "2rem", height: "2rem"}} role="status">
																		<span className="visually-hidden">Loading...</span>
																	</div>
																</div>
															</Col>
														</Row>
														</>
													}
												</div>
												{sendingGiftProcess.errors && sendingGiftProcess.errors.length &&
													<>
													<div className='alert alert-danger mt-3 mb-0' role="alert">
														<ul className='mb-0'>
															{sendingGiftProcess.errors.map((error, error_i) => (
																	<React.Fragment key={`error_key${error_i}`}>
																		<li>{error}</li>
																	</React.Fragment>
																))
															}
														</ul>
													</div>
													</>
												}
												{sendingGiftProcess.success && sendingGiftProcess.success === true &&
													<>
													<div className='alert alert-success mt-3 mb-0' role="alert">
														Notification sent.
													</div>
													</>
												}
												<p className="text-center pt-4 mb-0">We will send this person a link to claim their gift.</p>
												<div className="my-4 text-center ">
													<div className={`${styles.orCircleWrapper} ${styles.customWidth}`}>
														<div className={`${styles.orCircle}`}>OR</div>
													</div>
												</div>
												<div className="text-center mb-4">
													<h4>Share this link:</h4>
													<p className="my-2">Share this link by email or SMS</p>
													<div className="align-items-center d-flex flex-column flex-md-row justify-content-center">
														<div>
															<h5 className="mb-0">{shareLinkGuid}</h5>
														</div>
														<div className="ms-3">
															<button
																className="btn btn-lg"
																type="button"
																onClick={() =>  navigator.clipboard.writeText(shareLinkGuid)}
															>
															<FontAwesomeIcon icon={'copy'} />
															</button>
														</div>
													</div>
												</div>
											</div>
										))
										}
										<div className="text-center">
											<p className="mb-1">You may gift multiple trial packs as long as they are to different people. Only one gift per receiving household.</p>
											<p className="mb-1">Existing Healthycell customers are not eligible for the free trial pack.</p>
										</div>
									</div>
									</>
								}
								{shareLinkProcess.errors && shareLinkProcess.errors.length &&
									<>
									<div className='alert alert-danger mt-3' role="alert">
										<ul className='mb-0'>
											{shareLinkProcess.errors.map((error, error_i) => (
													<React.Fragment key={`error_key${error_i}`}>
														<li>{error}</li>
													</React.Fragment>
												))
											}
										</ul>
									</div>
									</>
								}
								</>
							}
						</div>
					</>
				}
				</>
			}
		</div>
	</Layout>
  )
}

export const Head = () => <Seo title="Gifting Program" />

export default GiftProgram