import React, { useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import _ from "lodash"

const {GATSBY_BACKEND_URL} = process.env;

function CustomerCheck(props) {
	const [customerEmail, setCustomerEmail] = useState();
	//const [customerEmail, setCustomerEmail] = useState("zuheil@rmi-international.com");
	const [customerChecking, setCustomerChecking] = useState(false);
	const [customerData, setCustomerData] = useState([]);

	const checkCustomer = (event) => {
		event.preventDefault();

		resetCustomerData();
		setCustomerChecking(true);
		axios({
			method: "post",
			url: `${GATSBY_BACKEND_URL}customer/check`,
			data: {'email': customerEmail}
			})
			.then(response => {
				var data = response.data;
				updateCustomerData(data);
			})
			.catch(error => {
				var result = {};
				if (typeof error.response.data === 'object') {
					if(error.response.data.errors) {
						result.errors = _.flatten(_.toArray(error.response.data.errors));
					} else if (error.response.data.message) {
						result.errors = [error.response.data.message];
					}
				} else {
					result.errors = ['Something went wrong. Please try again.'];
				}

				setCustomerData(result);
			})
			.then(() => {
				setCustomerChecking(false);
			});
	}

	const updateCustomerData = (value) => {
		setCustomerData(value);
		props.updateCustomerData(value);
	}

	const resetCustomerData = () => {
		setCustomerData([]);
		props.updateCustomerData([]);
	}

	return (
		<>
		<div className="text-center">
			<h2 className="mb-3">Send your friends a 7 day trial pack of Healthycell for FREE</h2>
			<h5 className="font-gotham-rounded-medium">Enter your email to confirm you are a Healthycell customer</h5>
		</div>
		<div className='my-4'>
			<Form onSubmit={checkCustomer}>
				<Row className=''>
					<Col xs="12" lg="4" className='offset-lg-3'>
						<Form.Group className="shadow-sm">
							<Form.Control
								onChange={event => setCustomerEmail(event.target.value)}
								placeholder="your email"
								aria-label="Email"
								type="email"
								required
								style={{'lineHeight':2}}
							/>
						</Form.Group>
					</Col>
					<Col xs="12" lg="3" className='my-3 my-lg-0 text-center text-lg-start'>
						<Button disabled={customerChecking} className='h-100 px-5 font-gotham-rounded-medium' variant="dark" type='submit'>Get Started</Button>
					</Col>
				</Row>
			</Form>
			{ customerChecking === true &&
				<>
				<Row>
					<Col>
						<div className="text-center py-2">
							<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</Col>
				</Row>
				</>
			}
			{customerData.errors && customerData.errors.length &&
				<>
				<div className='alert alert-danger mt-3' role="alert">
					<ul className='mb-0'>
						{customerData.errors.map((error, error_i) => (
								<React.Fragment key={`error_key${error_i}`}>
									<li>{error}</li>
								</React.Fragment>
							))
						}
					</ul>
				</div>
				</>
			}
		</div>
		</>
	)
}

export default CustomerCheck;