import React, { useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as styles from "../../components/style/product_selector.module.scss"

function ProductSelector(props){

	const [product_selected, setProduct_selected] = useState([]);
	const [combination_selected, setCombination_selected] = useState('');

	const selectProduct = (product) => {
		// check if array contains object
		var local_product_selected = [...product_selected];
		var indexToRemove = 0;
		const isFound = local_product_selected.some((element, index) => {
			if (element.id === product.id) {
				indexToRemove = index;
				return true;
			}

			return false;
		});
		
		if (isFound) {
			local_product_selected.splice(indexToRemove, 1); // remove
		} else {
			if(local_product_selected.length === 0) {
				local_product_selected.push(product); // add to list
			} else if(local_product_selected.length >= 1 && props.multiselect) {
				local_product_selected.push(product); // add to list
			} else if(local_product_selected.length >= 1 && !props.multiselect) {
				local_product_selected = [product];
			}
		}

		if(local_product_selected.length === 0) {
			continueAction(false);
		}

		setProduct_selected(local_product_selected);

		props.updateProductsSelected(local_product_selected);
	};

	const continueAction = (value) => {
		props.updateContinueAction(value);
	};

	const isSelected = (product) => {
		const isFound = product_selected.some(element => {
			if (element.id === product.id) {
				return true;
			}

			return false;
		});

		return isFound;
	}

	const selectCombo = (products , comb_selected) => {
		if(products.length) {
			var products_selected = [];
			products.forEach((auto_product_selected, auto_index) => {
				props.items.forEach((category, index) => {
					category.products.forEach((product, index) => {
						if(auto_product_selected === product.id) {
							products_selected.push(product);

						}
					});
				});
			});
			setProduct_selected(products_selected);
			props.updateProductsSelected(products_selected);
			setCombination_selected(comb_selected);
			continueAction(true);
		}
	};

	useEffect(() => { 
		if(props.autoSelectProducts && props.autoSelectProducts.length) {
			props.autoSelectProducts.forEach((auto_product_selected, auto_index) => {
				props.items.forEach((category, index) => {
					category.products.forEach((product, index) => {
						if(auto_product_selected === product.id) {
							selectProduct(product);
							continueAction(true);
						}
					});
				});
			});
		}
	}, [props.autoSelectProducts, props.items])

	return (
		<>
		{ props.items.length === 0 &&
			<>
			<Row>
				<Col>
					<div className="text-center py-2">
						<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</Col>
			</Row>
			</>
		}

		{ props.items.length > 0 &&
		<div>
			<Row className="mt-5">
				{ props.items.map((category,i) => (
					<React.Fragment key={`category_section_${i}`}>
						<Col xs="12" md="6" lg="3" className='p-4'>
							<div className={`text-center mb-5 mb-lg-4 ${styles.categoryWrapper}`}>
								<div className='d-flex justify-content-center align-items-center'>
									<img className={`img-fluid ${styles.imgCategoryTitle}`} src={category.icon} alt={category.name}/>		
									<h4 className={`ms-2 text-uppercase mb-0 ${styles.categoryTitle}`}>{category.name}</h4>
								</div>
								<div className="mt-4" dangerouslySetInnerHTML={{__html: category.description}}></div>
							</div>

							<div className='d-lg-none products'> {/* hide in desktop */}
								{category.products.map((product,p_i) => (
									<React.Fragment key={p_i}>
										{ (!props.onlyGiftItems || (props.onlyGiftItems && product.gift_shopify_id && product.gift_in_stock)) &&
											<div>
												<div>
													{props.showImage === true &&
														<>
															<img className={`img-fluid d-block mx-auto ${styles.imgProductSelection}`} src={product.image} alt={product.name}/>
														</>
													}
												</div>
												<div role="button" tabIndex={p_i} onKeyDown={event => selectProduct(product)} onClick={event => selectProduct(product)} className={`cursor-pointer border px-2 py-3 text-center mb-4 position-relative ${isSelected(product) ? 'bg-black text-white' : 'text-black'} `}>
													<div className={`d-none border rounded-circle position-absolute text-black ${styles.helpIcon}`}>i</div>
													{product.name}
												</div>
											</div>
										}
									</React.Fragment>
								))}
							</div>
						</Col>
					</React.Fragment>
				))
				}
			</Row>
			<Row className="d-lg-flex d-none row"> {/* show in desktop */}
				{ props.items.map((category,i) => (
					<React.Fragment key={`category_section_${i}`}>
						<Col xs="12" md="6" lg="3" className='p-4 pt-0'>
							<div className='products'>
								{category.products.map((product,p_i) => (
									<React.Fragment key={p_i}>
										{ (!props.onlyGiftItems || (props.onlyGiftItems && product.gift_shopify_id && product.gift_in_stock)) &&
											<div>
												<div>
													{props.showImage === true &&
														<>
															<img className={`img-fluid d-block mx-auto ${styles.imgProductSelection}`} src={product.image} alt={product.name}/>
														</>
													}
												</div>
												<div role="button" tabIndex={p_i} onKeyDown={event => selectProduct(product)} onClick={event => selectProduct(product)} className={`cursor-pointer border px-2 py-3 text-center mb-4 position-relative ${isSelected(product) ? 'bg-black text-white' : 'text-black'} `}>
													<div className={`d-none border rounded-circle position-absolute text-black ${styles.helpIcon}`}>i</div>
													{product.name}
												</div>
											</div>
										}
									</React.Fragment>
								))}
							</div>
						</Col>
					</React.Fragment>
				))
				}
			</Row>

			{props.multiselect &&
				<>
				<div className={`border-bottom border-top mb-4 py-4`}>
					<p className='text-center font-gotham-rounded-bold'>Popular Combinations</p>
					<div className={`d-flex flex-wrap align-content-between justify-content-center ${styles.popularCombinationWrapper}`}>
						<div className='mb-md-0 mb-2'><button className={`btn btn-outline-primary ${combination_selected === 'comb_1' ? 'active' : ''}`} style={{'--bs-btn-border-radius':'2px'}} onClick={event => selectCombo([1,7,5,3] , 'comb_1')} type='button'>Best Sellers</button></div>
						<div className='mb-md-0 mb-2'><button className={`btn btn-outline-primary ${combination_selected === 'comb_2' ? 'active' : ''}`} style={{'--bs-btn-border-radius':'2px'}} onClick={event => selectCombo([10,1,9], 'comb_2')} type='button'>Beautiful Mood</button></div>
						<div className='mb-md-0 mb-2'><button className={`btn btn-outline-primary ${combination_selected === 'comb_3' ? 'active' : ''}`} style={{'--bs-btn-border-radius':'2px'}} onClick={event => selectCombo([1,6], 'comb_3')} type='button'>Healthy Foundation</button></div>
						<div className='mb-md-0 mb-2'><button className={`btn btn-outline-primary ${combination_selected === 'comb_4' ? 'active' : ''}`} style={{'--bs-btn-border-radius':'2px'}} onClick={event => selectCombo([5,7,8], 'comb_4')} type='button'>Healthy Aging</button></div>
					</div>
				</div>
				</>
			}
		</div>
		}

		{ ((product_selected.length > 0) || (props.allowNoSelection !== undefined && props.allowNoSelection === true )) &&
			<>
				<div className='text-center'>
					<button onClick={event => continueAction(true)} type='button' style={{'--bs-btn-border-radius':'2px'}} className='btn btn-outline-primary'>Continue</button>
				</div>
			</>
		}
		</>
	);
}

export default ProductSelector;