import * as React from "react"
import { Container } from "react-bootstrap"

import LogoHC from '../../images/svg/hc_logo_blackv3.inline.svg'

const Header = ({ siteTitle }) => (
	<header className="bg-white">
		<Container>
			<div className="d-flex justify-content-center py-3">
				<div className="logo-size">
					<a href="https://healthycell.com">
						<LogoHC/>
					</a>
				</div>
			</div>
		</Container>
  	</header>
)

export default Header
